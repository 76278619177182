import React from "react"
import { Helmet } from "react-helmet"
import NavigationBar from "../components/Navbar"
import { Link } from "gatsby"
import loadable from "@loadable/component"
import SocialMedia from "../components/social-media"

const NewsletterStrip = loadable(() => import("../components/newsletterStrip"))
const Footer = loadable(() => import("../components/footer3"))

export default class popularTags extends React.Component {
  constructor(props) {
    super(props)

    this.tagsData = [
      {
        path: "/tags/coding-interview-guidance/",
        name: "Coding Interview Guidance",
      },
      {
        path: "/tags/coding-interview-concepts/",
        name: "Coding Interview Concepts",
      },
      {
        path: "/tags/coding-interview-questions/",
        name: "Coding Interview Questions",
      },
      {
        path: "/tags/machine-learning-concepts/",
        name: "Machine Learning Concepts",
      },
      {
        path: "/tags/machine-learning-projects/",
        name: "Machine Learning Projects",
      },
      {
        path: "/tags/system-design-concepts/",
        name: "System Design Concepts",
      },
      {
        path: "/tags/system-design-questions/",
        name: "System Design Questions",
      },
      {
        path: "/tags/system-design-interview/",
        name: "System Design Interview",
      },
      {
        path: "/tags/oops-concepts/",
        name: "OOP Design Concepts",
      },
      {
        path: "/tags/google-interview-questions/",
        name: "Google Interview Questions",
      },
      {
        path: "/tags/amazon-interview-questions/",
        name: "Amazon Interview Questions",
      },
      {
        path: "/tags/microsoft-interview-questions/",
        name: "Microsoft Interview Questions",
      },
      {
        path: "/tags/facebook-interview-questions/",
        name: "Facebook Interview Questions",
      },
      {
        path: "/tags/time-complexity-analysis/",
        name: "Time Complexity Analysis",
      },
      {
        path: "/tags/single-loop/",
        name: "Single loop",
      },
      {
        path: "/tags/recursion/",
        name: "Recursion",
      },
      {
        path: "/tags/binary-search/",
        name: "Binary Search",
      },
      {
        path: "/tags/divide-and-conquer/",
        name: "Divide and Conquer",
      },
      {
        path: "/tags/sorting/",
        name: "Sorting",
      },
      {
        path: "/tags/searching/",
        name: "Searching",
      },
      {
        path: "/tags/two-pointers/",
        name: "Two Pointers",
      },
      {
        path: "/tags/sliding-window/",
        name: "Sliding Window",
      },
      {
        path: "/tags/matrix/",
        name: "Matrix",
      },
      {
        path: "/tags/extra-memory/",
        name: "Extra Memory",
      },
      {
        path: "/tags/data-structures/",
        name: "Data Structures",
      },
      {
        path: "/tags/hashing/",
        name: "Hashing",
      },
      {
        path: "/tags/linked-list/",
        name: "Linked List",
      },
      {
        path: "/tags/stack/",
        name: "Stack",
      },
      {
        path: "/tags/queue/",
        name: "Queue",
      },
      {
        path: "/tags/binary-tree/",
        name: "Binary Tree",
      },
      {
        path: "/tags/dynamic-programming/",
        name: "Dynamic Programming",
      },
      {
        path: "/tags/string/",
        name: "String",
      },
      {
        path: "/tags/supervised-learning/",
        name: "Supervised Learning",
      },
      {
        path: "/tags/unsupervised-learning/",
        name: "Unsupervised Learning",
      },
      {
        path: "/tags/classification-problem/",
        name: "Classification",
      },
      {
        path: "/tags/regression-problem/",
        name: "Regression",
      },

      {
        path: "/tags/data-science/",
        name: "Data science",
      },
      {
        path: "/tags/data-processing/",
        name: "Data Preprocessing",
      },
      {
        path: "/tags/machine-learning-interview/",
        name: "Machine Learning Interview",
      },
      {
        path: "/tags/oops-principles/",
        name: "OOP Design Principles",
      },
      {
        path: "/tags/design-patterns/",
        name: "Design Patterns",
      },
      {
        path: "/tags/databases/",
        name: "Databases",
      },
      {
        path: "/tags/success-stories/",
        name: "Success Stories",
      },
    ]
  }
  render() {
    return (
      <div className="">
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>EnjoyAlgorithms Tags</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            content="We have categorized popular topics related to data structures and algorithms, machine learning, system design, and object-oriented programming using relevant tags."
          />
        </Helmet>

        <div className="sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
          <h1 className="mx-auto pt-6 lg:pt-8 xl:pt-12 tracking-wider text-xl lg:text-2xl 2xl:text-3xl leading-7 text-center  text-gray-900 sm:leading-none">
            Popular Tags
          </h1>

          <div className="mt-6 mb-8 container mx-auto px-4 md:px-6 lg:px-8 xl:px-10">
            <div className="ml-0">
              <nav className="mt-6 text-center text-white  tracking-wider text-sm md:text-base 2xl:text-lg flex flex-wrap items-center">
                {this.tagsData.map((tag, index) => (
                  <div className="py-4" key={index}>
                    <Link
                      to={tag.path}
                      className="px-4 py-2 2xl:py-3 m-2 shadow rounded border border-gray-600 bg-white text-gray-600 hover:bg-new-green hover:text-white"
                    >
                      {tag.name}
                    </Link>
                  </div>
                ))}
              </nav>
              <SocialMedia />

              <div>
                <NewsletterStrip />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
